/**
 * @generated SignedSource<<2042ceac1b020a9723b7a5bc0c4728f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardDisplayStyle = "CONTAIN" | "COVER" | "PADDED" | "%future added value";
export type RarityStrategyId = "OPENRARITY" | "%future added value";
export type CollectionCreateMutationDataTypeInput = {
  authorizedEditors?: ReadonlyArray<string> | null;
  bannerImageToken?: string | null;
  category?: string | null;
  categoryV2?: string | null;
  creatorFees?: ReadonlyArray<FeeDataInput> | null;
  defaultChain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "%future added value";
  description?: string | null;
  discordUrl?: string | null;
  displayData?: DisplayDataTypeInput | null;
  enabledRarities?: ReadonlyArray<RarityEnabledDataInputType> | null;
  externalUrl?: string | null;
  featuredImageToken?: string | null;
  instagramUsername?: string | null;
  isNsfw?: boolean | null;
  logoImageToken?: string | null;
  mediumUsername?: string | null;
  name: string;
  paymentAssets?: ReadonlyArray<string> | null;
  raritiesNote?: string | null;
  slug?: string | null;
  tags?: ReadonlyArray<string> | null;
  telegramUrl?: string | null;
  traitOffersEnabled?: boolean | null;
  twitterUsername?: string | null;
};
export type FeeDataInput = {
  address: string;
  basisPoints: number;
};
export type DisplayDataTypeInput = {
  cardDisplayStyle?: CardDisplayStyle | null;
  images?: ReadonlyArray<string> | null;
};
export type RarityEnabledDataInputType = {
  strategyId?: RarityStrategyId | null;
};
export type useCollectionFormCreateMutation$variables = {
  input: CollectionCreateMutationDataTypeInput;
};
export type useCollectionFormCreateMutation$data = {
  readonly collections: {
    readonly create: {
      readonly slug: string;
      readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
    };
  };
};
export type useCollectionFormCreateMutation = {
  response: useCollectionFormCreateMutation$data;
  variables: useCollectionFormCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "collectionInput",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCategory",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCollectionFormCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionMutationType",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "create",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineDataFragmentSpread",
                "name": "collection_url",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCollectionFormCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionMutationType",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "create",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a11716ae73534c43345a1981dc2a5260",
    "id": null,
    "metadata": {},
    "name": "useCollectionFormCreateMutation",
    "operationKind": "mutation",
    "text": "mutation useCollectionFormCreateMutation(\n  $input: CollectionCreateMutationDataTypeInput!\n) {\n  collections {\n    create(collectionInput: $input) {\n      slug\n      ...collection_url\n      id\n    }\n  }\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n"
  }
};
})();

(node as any).hash = "9d0093b1a0a70d3550b9657b92e5d351";

export default node;
